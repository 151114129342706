<template>
  <aRow class="content" :gutter="20">
    <aCol :span="22">
      <aRow :gutter="20">
        <aCol :span="5">
          <HayaTypeDateField
            label="Data do pagamento"
            :fieldKey="`fine_fee_pay_date_calendar_typed_${id}`"
            :fieldToTransferValue="`fine_fee_pay_date_calendar_${id}`"
            :form="form"
            :required="true"
          />

          <aFormItem class="none">
            <aInput v-decorator="[`fine_fee_pay_date_calendar_${id}`]" />
          </aFormItem>
        </aCol>

        <aCol :span="5">
          <HayaPriceField
            label="Valor"
            placeholder="Insira"
            :fieldKey="`fine_fee_value_calendar_${id}`"
            :form="form"
            :preIcon="true"
            :required="true"
          />
        </aCol>

        <aCol :span="8">
          <HayaSelectField
            label="Método de Pagamento"
            :fieldKey="`fine_fee_payment_method_calendar_${id}`"
            :list="paymentMethods"
            :required="true"
            :form="form"
            :allowClear="true"
            :showSearch="true"
            @onChangeSelectField="setPaymentMethodsFieldValues"
          />
        </aCol>

        <template
          v-if="
            ['Cartão de Crédito'].includes(
              form.getFieldValue(`fine_fee_payment_method_calendar_${id}`)
            )
          "
        >
          <aCol :span="6">
            <HayaTextField
              label="Número do cancelamento"
              :fieldKey="`fine_fee_credit_card_cancel_number_calendar_${id}`"
              :preIcon="true"
            />
          </aCol>

          <aCol :span="6">
            <HayaTextField
              label="NSU"
              :fieldKey="`fine_fee_credit_card_nsu_calendar_${id}`"
              :preIcon="true"
            />
          </aCol>

          <aCol :span="6">
            <HayaTextField
              label="TID"
              :fieldKey="`fine_fee_credit_card_tid_calendar_${id}`"
              :preIcon="true"
            />
          </aCol>

          <aCol :span="12">
            <aCheckbox
              class="mt-10 f12"
              v-model="CancellationMadeAtPurchaser"
              @change="onChangeCancellationMadeAtPurchaser"
            >
              Cancelamento efetuado na Adquirente
              <b v-if="CancellationMadeAtPurchaser">MAQ ID</b>
            </aCheckbox>
            <aFormItem class="none">
              <aInput
                v-decorator="[
                  `fine_fee_credit_card_cancellation_made_at_purchaser_${id}`,
                  { initialValue: CancellationMadeAtPurchaser },
                ]"
              />
            </aFormItem>
          </aCol>
        </template>

        <template
          v-if="
            ['Transferência Bancária/PIX'].includes(
              form.getFieldValue(`fine_fee_payment_method_calendar_${id}`)
            )
          "
        >
          <aCol :span="6">
            <HayaTextField
              label="Chave PIX (opcional)"
              :fieldKey="`fine_fee_bank_transfer_pix_key_calendar_${id}`"
              :preIcon="true"
            />
          </aCol>

          <aCol :span="8">
            <HayaSelectField
              label="Banco"
              :fieldKey="`fine_fee_bank_transfer_bank_number_calendar_${id}`"
              :list="
                banks.map((bank) => {
                  return {
                    label: `${bank.numero} - ${bank.nome}`,
                    value: bank.id,
                  };
                })
              "
              :required="true"
              :form="form"
              :allowClear="true"
              :showSearch="true"
            />
          </aCol>

          <aCol :span="4">
            <HayaTextField
              label="Agencia"
              :fieldKey="`fine_fee_bank_transfer_bank_agency_calendar_${id}`"
              :preIcon="true"
            />
          </aCol>

          <aCol :span="6">
            <HayaTextField
              label="Conta"
              :fieldKey="`fine_fee_bank_transfer_bank_account_calendar_${id}`"
              :preIcon="true"
            />
          </aCol>

          <aCol :span="6">
            <HayaTextField
              label="Operação"
              :fieldKey="`fine_fee_bank_transfer_bank_operation_calendar_${id}`"
              :preIcon="true"
            />
          </aCol>
        </template>
      </aRow>
    </aCol>

    <aCol :span="2">
      <aButton
        class="calendar"
        type="primary"
        icon="calendar"
        :disabled="true"
        @click="onClickCreatePaymentEvent"
      />
    </aCol>
  </aRow>
</template>

<script>
import banks from "@/json/banks.json";
import HayaSelectField from "@/components/general/fields/HayaSelectField.vue";
import HayaTypeDateField from "@/components/general/fields/HayaTypeDateField.vue";
import HayaTextField from "@/components/general/fields/HayaTextField.vue";
import HayaPriceField from "@/components/general/fields/HayaPriceField.vue";

export default {
  name: "CreditLetterFineFeePaymentCollapse",
  props: {
    id: Number,
    form: Object,
    action: String,
    creditLetter: Object,
    tempCreditLetter: Object,
    creditLetterFieldsDisabled: Boolean,
  },
  components: {
    HayaSelectField,
    HayaTypeDateField,
    HayaTextField,
    HayaPriceField,
  },
  data() {
    return {
      paymentMethods: [],
      banks,
      CancellationMadeAtPurchaser: false,
    };
  },
  mounted() {
    this.getPaymentMethods();

    this.form.setFieldsValue({
      [`fine_fee_pay_date_calendar_typed_${this.id}`]:
        this.tempCreditLetter[`fine_fee_pay_date_calendar_typed_${this.id}`],
      [`fine_fee_pay_date_calendar_${this.id}`]:
        this.tempCreditLetter[`fine_fee_pay_date_calendar_${this.id}`],
      [`fine_fee_value_calendar_${this.id}`]:
        this.tempCreditLetter[`fine_fee_value_calendar_${this.id}`],
      [`fine_fee_payment_method_calendar_${this.id}`]:
        this.tempCreditLetter[`fine_fee_payment_method_calendar_${this.id}`],
    });

    this.setPaymentMethodsFieldValues();
  },
  methods: {
    onClickCreatePaymentEvent() {
      console.log("onClickCreatePaymentEvent", {
        name: `Multa Cancelamento Contrato ${this.form.getFieldValue(
          `canceled_contract_id`
        )}`,
        description: `Multa Cancelamento Contrato ${this.form.getFieldValue(
          `canceled_contract_id`
        )}`,
        type: "Pagamento",
        status: "Pendente",
        date: this.form.getFieldValue(`fine_fee_pay_date_calendar`),
        company_id: this.creditLetter.details.raw.company_id,
        company_branch_id: this.creditLetter.details.raw.company_branch_id,
        module: "credit-letter",
        module_id: this.creditLetter.details.id,
        value: this.form.getFieldValue(`fine_value_calendar`),
        payment_method: this.form.getFieldValue(
          `fine_fee_payment_method_calendar`
        ),
        bank_id: this.form.getFieldValue(`fine_fee_bank_id_calendar`),
      });
    },
    getPaymentMethods() {
      this.$http
        .get(
          `/category-entry/list?page=1&per_page=10&category_id=14&order-by=name&order=ascend`
        )
        .then(({ data }) => {
          this.paymentMethods = data.data
            .filter((paymentMethod) =>
              ["Cartão de Crédito", "Transferência Bancária/PIX"].includes(
                paymentMethod.name
              )
            )
            .map((paymentMethod) => {
              return {
                label: paymentMethod.name,
                value: paymentMethod.name,
              };
            });
        });
    },
    setPaymentMethodsFieldValues() {
      setTimeout(() => {
        this.CancellationMadeAtPurchaser = ["1", true, "true"].includes(
          this.tempCreditLetter[
            `fine_fee_credit_card_cancellation_made_at_purchaser_${this.id}`
          ]
        )
          ? true
          : false;

        this.form.setFieldsValue({
          [`fine_fee_credit_card_cancel_number_calendar_${this.id}`]:
            this.tempCreditLetter[
              `fine_fee_credit_card_cancel_number_calendar_${this.id}`
            ],
          [`fine_fee_credit_card_nsu_calendar_${this.id}`]:
            this.tempCreditLetter[
              `fine_fee_credit_card_nsu_calendar_${this.id}`
            ],
          [`fine_fee_credit_card_tid_calendar_${this.id}`]:
            this.tempCreditLetter[
              `fine_fee_credit_card_tid_calendar_${this.id}`
            ],
          [`fine_fee_bank_transfer_pix_key_calendar_${this.id}`]:
            this.tempCreditLetter[
              `fine_fee_bank_transfer_pix_key_calendar_${this.id}`
            ],
          [`fine_fee_bank_transfer_bank_number_calendar_${this.id}`]:
            this.tempCreditLetter[
              `fine_fee_bank_transfer_bank_number_calendar_${this.id}`
            ],
          [`fine_fee_bank_transfer_bank_agency_calendar_${this.id}`]:
            this.tempCreditLetter[
              `fine_fee_bank_transfer_bank_agency_calendar_${this.id}`
            ],
          [`fine_fee_bank_transfer_bank_account_calendar_${this.id}`]:
            this.tempCreditLetter[
              `fine_fee_bank_transfer_bank_account_calendar_${this.id}`
            ],
          [`fine_fee_bank_transfer_bank_operation_calendar_${this.id}`]:
            this.tempCreditLetter[
              `fine_fee_bank_transfer_bank_operation_calendar_${this.id}`
            ],
        });
      }, 50);
    },
    onChangeCancellationMadeAtPurchaser(value) {
      this.form.setFieldsValue({
        [`fine_fee_credit_card_cancellation_made_at_purchaser_${this.id}`]:
          value.target.checked,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar {
  background: #f8bb03;
  border-color: #f8bb03;
  padding: 0;
  margin-top: 6px;
  &:hover {
    background: #e6ad03;
    border-color: #e6ad03;
  }
}
</style>

<style lang="sass" scoped>
.content
  border-top: 1px solid #ccc
  padding: 20px 20px 0
  border-radius: 0 0 6px 6px
  background: #fff
</style>
