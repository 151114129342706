<template>
  <aForm
    :form="form"
    @onFieldsChange="updateTempCreditLetter"
    @submit="handleSubmit"
  >
    <aRow :gutter="20">
      <aCol :span="24">
        <HayaRadioField
          label="Tipo de produto"
          optionFilterProp="txt"
          fieldKey="product_type"
          :form="form"
          :required="true"
          :disabled="creditLetterFieldsDisabled()"
          :options="productsType"
        />
      </aCol>

      <aCol :class="action === 'edit' ? '' : 'none'" :span="6">
        <HayaTextField
          label="Código"
          placeholder="Auto gerado ao criar"
          fieldKey="code"
          fieldClass="readonly"
          :form="form"
          :preIcon="true"
        />
      </aCol>

      <aCol :span="4">
        <HayaDatePickerField
          label="Data de Recebimento"
          placeholder="Selecione"
          fieldKey="receipt_date"
          :form="form"
          :fieldClass="creditLetterFieldsDisabled() ? 'readonly' : ''"
          @onChangeDatepicker="onChangeReceiptDate"
        />
      </aCol>

      <aCol :span="4">
        <HayaValueField
          label="Valor (R$)"
          placeholder="Escreva"
          fieldKey="value"
          :fieldClass="creditLetterFieldsDisabled() ? 'readonly' : ''"
          :vCurrency="{}"
          :form="form"
          :required="true"
          :preIcon="true"
        />
      </aCol>

      <aCol :span="4">
        <HayaSelectField
          label="Empresa"
          optionFilterProp="txt"
          fieldKey="company_id"
          :fieldClass="creditLetterFieldsDisabled() ? 'readonly' : ''"
          :form="form"
          :required="true"
          :list="companiesList"
          :allowClear="true"
          :showSearch="true"
          @onChangeSelectField="onChangeCompany"
        />
      </aCol>

      <aCol :span="6">
        <HayaSelectField
          label="Filial"
          optionFilterProp="txt"
          fieldKey="company_branch_id"
          :fieldClass="creditLetterFieldsDisabled() ? 'readonly' : ''"
          :form="form"
          :required="true"
          :list="companyBranchesList"
          :allowClear="true"
          :showSearch="true"
        />
      </aCol>

      <aCol :span="6">
        <HayaValueField
          label="Lucratividade original (R$)"
          placeholder="Escreva"
          fieldKey="profit_value"
          :fieldClass="creditLetterFieldsDisabled() ? 'readonly' : ''"
          :vCurrency="{}"
          :form="form"
          :required="false"
          :preIcon="true"
        />
      </aCol>

      <aCol :span="7">
        <HayaSelectField
          label="Cliente"
          placeholder="Selecione ou busque id ou nome"
          fieldKey="customer_id"
          optionFilterProp="txt"
          :fieldClass="creditLetterFieldsDisabled() ? 'readonly' : ''"
          :form="form"
          :list="mapCustomersList(customers.list)"
          :allowClear="true"
          :showSearch="true"
          :loading="customers.loading"
          @onSearchSelectField="onSearchCustomers"
          @onSelectSelectField="customers.loading = false"
        />
      </aCol>

      <aCol :span="1">
        <a-button
          v-if="!creditLetterFieldsDisabled()"
          class="relative"
          type="primary"
          shape="circle"
          icon="plus"
          size="small"
          style="left: -6px; top: 13px"
          @click="onClickOpenCreateCustomerModal"
        />
      </aCol>

      <aCol :span="4">
        <HayaSelectField
          label="Status"
          optionFilterProp="txt"
          fieldKey="status"
          :form="form"
          :required="true"
          :list="
            creditCardStatusList.map(({ name }) => {
              return {
                label: name,
                value: name,
              };
            })
          "
          :allowClear="true"
          :showSearch="true"
          @onChangeSelectField="onChangeStatus"
        />
      </aCol>

      <aCol :span="6">
        <HayaSelectField
          label="Emissor"
          optionFilterProp="txt"
          fieldKey="issuer_id"
          :fieldClass="creditLetterFieldsDisabled() ? 'readonly' : ''"
          :form="form"
          :required="false"
          :list="mapUserList(user.list)"
          :allowClear="true"
          :showSearch="true"
        />
      </aCol>

      <aCol :span="6">
        <HayaDatePickerField
          label="Data de Validade"
          placeholder="Selecione"
          fieldKey="expires_at"
          :form="form"
          :fieldClass="creditLetterFieldsDisabled() ? 'readonly' : ''"
        />
      </aCol>

      <aCol :span="4">
        <HayaTextField
          label="ID Voyage"
          placeholder="Insira"
          fieldKey="voyage_id"
          :form="form"
          :preIcon="true"
          :fieldClass="creditLetterFieldsDisabled() ? 'readonly' : ''"
        />
      </aCol>

      <aCol v-if="action === 'edit'" :span="24">
        <CreditLetterContractSection
          v-if="
            creditLetter.details.meta.canceled_contract_id &&
            creditLetter.details.meta.generated_contract_id
          "
          :form="form"
          :action="action"
          :tempCreditLetter="tempCreditLetter"
          :creditLetter="creditLetter"
          :creditLetterFieldsDisabled="creditLetterFieldsDisabled()"
          ref="CreditLetterContractSection"
        />
      </aCol>

      <aCol
        v-if="form.getFieldValue(`product_type`) === 'Passagem Aérea Milhas'"
        :span="24"
      >
        <CreditLetterMilesSection
          :form="form"
          :action="action"
          :tempCreditLetter="tempCreditLetter"
          :creditLetter="creditLetter"
          :creditLetterFieldsDisabled="creditLetterFieldsDisabled()"
        />
      </aCol>

      <aCol class="a-right mb-20" :span="24">
        <a-divider />
        <a-button
          type="primary"
          html-type="submit"
          :loading="loadingCreditLetter"
        >
          {{ action === "edit" ? "Atualizar dados" : "Cadastrar" }}
        </a-button>
      </aCol>
    </aRow>

    <aModal
      width="500px"
      :visible="openCreateCustomerModal"
      :footer="false"
      @cancel="openCreateCustomerModal = false"
    >
      <template #title> CADASTRAR CLIENTE </template>
      <a-form @submit.prevent="submitCustomer" :form="customerForm">
        <a-row
          class="box box-contractor"
          :gutter="[20, 0]"
          style="margin-top: -20px"
        >
          <CustomerForm
            v-if="openCreateCustomerModal"
            :customerForm="customerForm"
            :requiredFields="{
              person_type: true,
              company_name: false,
              trading_name: false,
              cnpj: false,
              first_name: true,
              last_name: true,
              email: true,
              birthday: false,
              cpf: false,
              rg: false,
              rg_emissor: false,
              rg_state: false,
              mobile_phone: false,
              phone: false,
              status: false,
              cep: false,
              location_street: false,
              location_number: false,
              location_complement: false,
              location_neighborhood: false,
              location_city: false,
              location_state: false,
              location_country: false,
            }"
          />
          <a-col class="a-center mt-20" :span="24">
            <a-button
              icon="user"
              type="primary"
              :loading="loadingSubmitCustomer"
              html-type="submit"
            >
              Cadastrar cliente
            </a-button>
          </a-col>
        </a-row>
      </a-form>
    </aModal>
  </aForm>
</template>

<script>
import _ from "lodash";
import HayaTextField from "@/components/general/fields/HayaTextField.vue";
import HayaSelectField from "@/components/general/fields/HayaSelectField.vue";
import HayaRadioField from "@/components/general/fields/HayaRadioField.vue";
import HayaValueField from "@/components/general/fields/HayaValueField.vue";
import HayaDatePickerField from "@/components/general/fields/HayaDatePickerField.vue";
import CustomerForm from "@/components/customers/forms/CustomerForm.vue";

//reuse
import customerMixins from "@/components/customers/mixins/customerMixins";
import formatThings from "@/mixins/general/formatThings";
import userMixins from "@/mixins/user/userMixins";
import CreditLetterMilesSection from "../sections/CreditLetterMilesSection.vue";
import CreditLetterContractSection from "../sections/CreditLetterContractSection.vue";

export default {
  name: "CreditLetterForm",
  props: {
    action: String,
    creditLetter: Object,
    creditCardStatusList: Array,
    productsType: Array,
    tempCreditLetter: Object,
  },
  components: {
    HayaTextField,
    HayaSelectField,
    HayaRadioField,
    HayaValueField,
    HayaDatePickerField,
    CreditLetterMilesSection,
    CreditLetterContractSection,
    CustomerForm,
  },
  mixins: [customerMixins, userMixins, formatThings],
  data() {
    return {
      form: this.$form.createForm(this, {
        onValuesChange: this.updateTempCreditLetter,
      }),
      customerForm: this.$form.createForm(this),
      loadingSubmitCustomer: false,
      loadingCreditLetter: false,
      companiesList: [],
      companyBranchesList: [],
      openCreateCustomerModal: false,
    };
  },
  beforeMount() {
    this.getCompanies();
    this.user.pagination.perPage = 100;
    this.getUsers();
  },
  mounted() {
    if (this.action === "edit") {
      this.onChangeCompany(this.creditLetter.details.raw.company_id);
      this.setCreditLetterFieldsValue();
      this.customers.filters.customerName =
        this.creditLetter.details.raw.customer_id;
      this.getCustomers();
    } else {
      this.getCustomers();
    }
  },
  methods: {
    updateTempCreditLetter(props, fields) {
      if (fields) {
        let field = Object.keys(fields)[0],
          value = Object.values(fields)[0];

        this.tempCreditLetter[field] = value;

        if (Array.isArray(value))
          this.tempCreditLetter[field] = JSON.stringify(value);

        this.$emit("updatedTempCreditLetter", this.tempCreditLetter);
      }

      props;
    },
    onChangeStatus(value) {
      this.$refs.CreditLetterContractSection.$emit("onChangeStatus", value);
    },
    onSearchCustomers: _.debounce(function (e) {
      if (e.length > 1) {
        this.customers.filters.customerName = e;
        this.getCustomers();
      }
    }, 600),
    onClickOpenCreateCustomerModal() {
      this.openCreateCustomerModal = true;

      setTimeout(() => {
        this.customerForm.setFieldsValue({
          location_country: "Brasil",
        });
      }, 100);
    },
    onChangeReceiptDate(e) {
      const receiptDate = e?._d;
      const expiresAt = e?._d;
      expiresAt.setFullYear(expiresAt.getFullYear() + 1);
      this.form.setFieldsValue({
        expires_at: this.formatDateReverse(expiresAt),
      });

      setTimeout(() => {
        receiptDate.setFullYear(receiptDate.getFullYear() - 1);
        this.form.setFieldsValue({
          receipt_date: this.formatDateReverse(receiptDate),
        });
      }, 100);
    },
    creditLetterFieldsDisabled() {
      let flag = false;
      if (this.action === "edit")
        if (
          this.creditLetter.details.status === "Ativo" ||
          this.creditLetter.details.status === "Auditoria"
        )
          flag = true;

      return flag;
    },
    setCreditLetterFieldsValue() {
      this.form.setFieldsValue({
        product_type: this.tempCreditLetter.product_type,
      });

      setTimeout(() => {
        this.form.setFieldsValue(this.tempCreditLetter);
      }, 100);
    },
    onChangeCompany(id) {
      this.getCompanyBranches(id);
    },
    getCompanies() {
      this.$http.get(`/company/list?page=1&per_page=100`).then(({ data }) => {
        this.companiesList = data.data.map((company) => {
          return {
            label: `${company.id} - ${company.trading_name}`,
            value: company.id,
          };
        });
      });
    },
    getCompanyBranches(companyId) {
      this.$http
        .get(
          `/company-branch/list?page=1&per_page=100&status=Ativo&company_id=${companyId}`
        )
        .then(({ data }) => {
          this.companyBranchesList = data.data.map((companyBranch) => {
            return {
              label: `${companyBranch.id} - ${companyBranch.name}`,
              value: companyBranch.id,
            };
          });
        });
    },
    generateChangeLog() {
      let log = "";
      let dataFromDatabase = {
        ...this.creditLetter.details.raw,
        ...this.creditLetter.details.meta,
      };
      const formatDateArr = ["expires_at", "receipt_date"];

      for (const key in this.tempCreditLetter) {
        if (
          this.tempCreditLetter[key] !== dataFromDatabase[key] &&
          key !== "credit_letter_miles_rows"
        ) {
          let theValue = this.tempCreditLetter[key];
          let theValueFromDb = dataFromDatabase[key];

          if (formatDateArr.includes(key))
            theValue = this.formatMultiDates(this.tempCreditLetter[key]);

          if (formatDateArr.includes(key))
            theValueFromDb = this.formatMultiDates(dataFromDatabase[key]);

          log += `${key}: <b>${theValueFromDb || "' '"}</b> para <b>${
            theValue || "' '"
          }</b>;<br>`;
        }
      }

      if (log)
        this.$http.post("/log/create", {
          user_id: this.$store.state.userData.id,
          module_id: this.tempCreditLetter.id,
          module: "credit-letter",
          action: "update",
          description: `<div class="f12 mb-10">Alterações:</div>
          <div class="f12 mb-10">${log} </div>
          <div class="f12 mb-10"> <b>Por:</b> ${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</div>`,
        });
    },
    onSubmitEditCreditLetter() {
      this.tempCreditLetter.value = this.tempCreditLetter.value
        ? this.tempCreditLetter.value.replace(",", ".")
        : 0;
      this.tempCreditLetter.profit_value = this.tempCreditLetter.profit_value
        ? this.tempCreditLetter.profit_value.replace(",", ".")
        : 0;
      this.tempCreditLetter.currency = "brl";

      this.$http
        .put(`/credit-letter/update`, this.tempCreditLetter)
        .then(({ data }) => {
          this.generateChangeLog();
          this.$message.success(data.message);
          this.$emit("onEditCreditLetter");
          this.loadingCreditLetter = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.$message.error(response.data.message);
          this.loadingCreditLetter = false;
        });
    },
    onSubmitCreateCreditLetter() {
      this.tempCreditLetter.user_id = this.$store.state.userData.id;
      this.tempCreditLetter.value = this.tempCreditLetter.value
        ? this.tempCreditLetter.value.replace(",", ".")
        : 0;
      this.tempCreditLetter.profit_value = this.tempCreditLetter.profit_value
        ? this.tempCreditLetter.profit_value.replace(",", ".")
        : 0;
      this.tempCreditLetter.currency = "brl";
      this.tempCreditLetter.available_value = this.tempCreditLetter.value;

      this.$http
        .post(`/credit-letter/create`, this.tempCreditLetter)
        .then(({ data }) => {
          this.$message.success(data.message);

          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: data.id,
            module: "credit-letter",
            action: "create",
            description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} criou uma nova carta de crédito ID ${data.id}.`,
          });

          this.$emit("onCreateCreditLetter");

          this.loadingCreditLetter = false;
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);

          this.loadingCreditLetter = false;
        });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          this.loadingCreditLetter = true;
          this.action === "edit"
            ? this.onSubmitEditCreditLetter()
            : this.onSubmitCreateCreditLetter();
        } else {
          this.$message.warning("Alguns campos não foram preenchidos.");
        }
      });
    },
    submitCustomer() {
      this.customerForm.validateFields(async (err, values) => {
        console.log(err, values);
        if (!err) {
          this.loadingSubmitCustomer = true;
          values.action = "create-customer";
          values.avatar = "";
          values.status = "Ativo";
          values.user_id = this.$store.state.userData.id;
          values.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          this.$http
            .post("/customer/create", values)
            .then(({ data }) => {
              this.$message.success(data.message);

              this.customers.filters.customerName = data.id;
              this.getCustomers();

              this.form.setFieldsValue({
                customer_id: data.id,
              });
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
            })
            .finally(() => {
              this.openCreateCustomerModal = false;
              this.loadingSubmitCustomer = false;
            });
        } else {
          this.$message.warning(
            "Alguns campos não foram preenchidos. Verifique todas as abas."
          );
        }
      });
    },
  },
};
</script>
