var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('a-divider',{staticClass:"mt-10 mb-20"}),_vm._v(" "),_c('aFormItem',{staticClass:"none"},[_c('aInput',{directives:[{name:"decorator",rawName:"v-decorator",value:(['form_worker']),expression:"['form_worker']"}]})],1),_vm._v(" "),_c('h2',{staticClass:"f14 fw-600 cprimary mb-10"},[_vm._v("DADOS DA MULTA DE CANCELAMENTO")]),_vm._v(" "),_c('aRow',{staticClass:"data-row mb-30"},[_c('aCol',{attrs:{"span":6}},[_c('span',{staticClass:"label"},[_vm._v("ID Contrato (Gerado):")]),_vm._v(" "),(_vm.creditLetter.details.meta.generated_contract_id)?[_c('a',{on:{"click":function($event){return _vm.$router.push(
              `/contracts/list?open-id=${_vm.creditLetter.details.meta.generated_contract_id}&open-modal=true`
            )}}},[_vm._v(_vm._s(_vm.creditLetter.details.meta.generated_contract_id))])]:[_vm._v(" --- ")]],2),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('span',{staticClass:"label"},[_vm._v("ID Contrato (Cancelado):")]),_vm._v(" "),(_vm.creditLetter.details.meta.canceled_contract_id)?[_c('a',{on:{"click":function($event){return _vm.$router.push(
              `/contracts/list?open-id=${_vm.creditLetter.details.meta.canceled_contract_id}&open-modal=true`
            )}}},[_vm._v(_vm._s(_vm.creditLetter.details.meta.canceled_contract_id))])]:[_vm._v(" --- ")]],2),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('span',{staticClass:"label"},[_vm._v("ID Venda (Nova):")]),_vm._v(" "),(_vm.creditLetter.details.meta.generated_sale_id)?[_c('a',{on:{"click":function($event){return _vm.$router.push(
              `/sales/list?id=${_vm.creditLetter.details.meta.generated_sale_id}&open-modal=true`
            )}}},[_vm._v("\n          "+_vm._s(_vm.creditLetter.details.meta.generated_sale_id)+"\n        ")])]:[_vm._v(" --- ")]],2),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('span',{staticClass:"label"},[_vm._v("ID Venda (Cancelada):")]),_vm._v(" "),(_vm.creditLetter.details.meta.canceled_sale_id)?[_c('a',{on:{"click":function($event){return _vm.$router.push(
              `/sales/list?id=${_vm.creditLetter.details.meta.canceled_sale_id}&open-modal=true`
            )}}},[_vm._v("\n          "+_vm._s(_vm.creditLetter.details.meta.canceled_sale_id)+"\n        ")])]:[_vm._v(" --- ")]],2)],1),_vm._v(" "),_c('aRow',{attrs:{"gutter":20}},[_c('aCol',{attrs:{"span":4}},[_c('HayaPriceField',{attrs:{"label":"Multa do Cliente","placeholder":"Insira","fieldKey":"fine_fee_value","fieldClass":'readonly',"required":false,"form":_vm.form,"preIcon":true}})],1),_vm._v(" "),_c('aCol',{attrs:{"span":4}},[_c('HayaPriceField',{attrs:{"label":"Multa do Fornecedor","placeholder":"Insira","fieldKey":"supplier_fine_fee_value","fieldClass":'readonly',"required":false,"form":_vm.form,"preIcon":true}})],1),_vm._v(" "),_c('aCol',{attrs:{"span":4}},[_c('HayaPriceField',{attrs:{"label":"Valor Net","placeholder":"Insira","fieldKey":"fine_fee_net_value","required":false,"form":_vm.form,"preIcon":true}})],1),_vm._v(" "),_c('aCol',{attrs:{"span":4}},[_c('HayaPriceField',{attrs:{"label":"Taxas","placeholder":"Insira","fieldKey":"fine_fee_tax_value","required":false,"form":_vm.form,"preIcon":true}})],1)],1),_vm._v(" "),(_vm.tempCreditLetter.status === 'Pagamento Ao Cliente')?[_c('h2',{staticClass:"f14 fw-600 cprimary mb-30"},[_vm._v("DADOS PARA PAGAMENTO AO CLIENTE")]),_vm._v(" "),_c('aRow',[_c('aCol',{attrs:{"span":24}},[_c('HayaRadioField',{attrs:{"label":"Status do Pagamento do cliente","fieldKey":"fine_fee_payment_status","form":_vm.form,"required":true,"disabled":_vm.creditLetterFieldsDisabled,"options":[
            {
              label: 'Aprovado',
              value: 'Aprovado',
            },
            {
              label: 'Cancelado',
              value: 'Cancelado',
            },
          ]}})],1)],1),_vm._v(" "),(_vm.form.getFieldValue(`fine_fee_payment_status`) === 'Aprovado')?[_vm._l((_vm.repeaterRows),function({ id },i){return _c('div',{key:i,staticClass:"card"},[_c('div',{on:{"click":function($event){_vm.activeCollapse == id
              ? (_vm.activeCollapse = '')
              : (_vm.activeCollapse = id)}}},[_c('aRow',{staticClass:"head",attrs:{"gutter":10}},[_c('aCol',{attrs:{"span":23}},[_c('span',{staticClass:"ico cprimary"},[_c('a-icon',{attrs:{"type":"dollar"}}),_vm._v(" "),_c('span',{staticClass:"num"},[_vm._v(_vm._s(id))])],1),_vm._v("\n              PAGAMENTO\n            ")]),_vm._v(" "),(!_vm.creditLetterFieldsDisabled)?_c('aCol',{attrs:{"span":1}},[(_vm.repeaterRows.length == id)?_c('aButton',{staticClass:"relative",attrs:{"type":"danger","shape":"circle","icon":"delete","size":"small"},on:{"click":function($event){return _vm.removeRow(id)}}}):_vm._e()],1):_vm._e()],1)],1),_vm._v(" "),(_vm.activeCollapse == id)?[_c('CreditLetterFineFeePaymentCollapse',{attrs:{"form":_vm.form,"action":_vm.action,"id":id,"tempCreditLetter":_vm.tempCreditLetter,"creditLetter":_vm.creditLetter,"creditLetterFieldsDisabled":_vm.creditLetterFieldsDisabled}})]:_vm._e()],2)}),_vm._v(" "),_c('div',{staticClass:"a-center mt-20 mb-20"},[(!_vm.creditLetterFieldsDisabled)?_c('aButton',{staticClass:"f12",on:{"click":function($event){return _vm.addRow(_vm.repeaterRows.length)}}},[_vm._v("\n          Adicionar pagamento\n        ")]):_vm._e(),_vm._v(" "),_c('aFormItem',{staticClass:"none"},[_c('aInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'fine_fee_payment_rows',
              { initialValue: _vm.repeaterRowsStr },
            ]),expression:"[\n              'fine_fee_payment_rows',\n              { initialValue: repeaterRowsStr },\n            ]"}]})],1)],1)]:_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }