<template>
  <section>
    <CreditLetterModalHeader
      v-if="creditLetter.details.id"
      :creditLetter="creditLetter"
    />

    <div class="travel-tabs">
      <div class="tab-icons">
        <div
          class="tab grayscale"
          :class="activeTab == 'data' ? 'active' : ''"
          @click="portalActiveTab('data')"
        >
          <img
            src="@/assets/images/dashboard/credit-letter/credit-letter.png"
            alt="img"
            height="28"
          />
        </div>

        <div
          class="tab grayscale"
          :class="activeTab == 'extract' ? 'active' : ''"
          @click="portalActiveTab('extract')"
        >
          <aBadge :count="totalTransactions">
            <img
              src="@/assets/images/dashboard/credit-letter/extract.png"
              alt="img"
              height="28"
            />
          </aBadge>
        </div>

        <div
          class="tab grayscale"
          :class="activeTab == 'notes' ? 'active' : ''"
          @click="portalActiveTab('notes')"
        >
          <aBadge :count="totalNotes">
            <img
              src="@/assets/images/dashboard/contracts/notes-ico.png"
              alt="img"
              height="28"
            />
          </aBadge>
        </div>

        <div
          class="tab grayscale"
          :class="activeTab == 'logs' ? 'active' : ''"
          @click="portalActiveTab('logs')"
        >
          <aBadge :count="totalLogs" :offset="[10, 0]">
            <img
              src="@/assets/images/dashboard/contracts/logs-ico.png"
              alt="img"
              height="23"
            />
          </aBadge>
        </div>
      </div>

      <div class="tab-content">
        <div class="tab-scroll">
          <template
            v-if="tempCreditLetter.id !== undefined && activeTab === 'data'"
          >
            <h3 class="title">Dados da Carta de Crédito</h3>

            <CreditLetterForm
              class="mt-20"
              action="edit"
              :creditCardStatusList="creditCardStatusList"
              :creditLetter="creditLetter"
              :tempCreditLetter="tempCreditLetter"
              :productsType="productsType"
              @updatedTempCreditLetter="updatedTempCreditLetter"
            />
          </template>

          <template v-if="activeTab === 'extract'">
            <h3 class="title">Extrato</h3>
            <CreditLetterExtract
              :creditLetter="creditLetter"
              @onCreateTransaction="onCreateTransaction"
            />
          </template>

          <template v-if="activeTab === 'notes'">
            <h3 class="title">Notas</h3>
            <Notes
              :moduleId="creditLetter.details.id"
              @onLoadNotes="onLoadNotes"
              module="credit-letter"
            />
          </template>

          <template v-if="activeTab === 'logs'">
            <h3 class="title">Logs</h3>
            <LogsTimelineV2
              class="mt-20"
              module="credit-letter"
              @onLoadLogs="onLoadLogs"
              :id="creditLetter.details.id"
            />
          </template>
        </div>
      </div>
    </div>
    <!-- {{ tempCreditLetter }} -->
  </section>
</template>

<script>
import creditLetterReusables from "../mixins/creditLetterReusables";
import reusableThings from "@/mixins/general/reusableThings";

import CreditLetterModalHeader from "../sections/CreditLetterModalHeader.vue";
import CreditLetterExtract from "../tabs/CreditLetterExtract.vue";
import Notes from "@/components/general/Notes.vue";
import LogsTimelineV2 from "@/components/logs/LogsTimelineV2.vue";
import CreditLetterForm from "../forms/CreditLetterForm.vue";

export default {
  name: "CreditLettersModal",
  props: {
    creditLetterId: String,
    creditCardStatusList: Array,
  },
  components: {
    CreditLetterModalHeader,
    CreditLetterExtract,
    CreditLetterForm,
    LogsTimelineV2,
    Notes,
  },
  mixins: [creditLetterReusables, reusableThings],
  data() {
    return {
      activeTab: "data",
      totalTransactions: 0,
      totalNotes: 0,
      totalLogs: 0,
      tempCreditLetter: {},
    };
  },
  mounted() {
    this.getCreditLetter();
  },
  methods: {
    updatedTempCreditLetter(tempCreditLetter) {
      setTimeout(() => {
        this.tempCreditLetter = {
          ...this.tempCreditLetter,
          ...tempCreditLetter,
        };
      }, 100);
    },
    portalActiveTab(active) {
      setTimeout(() => {
        this.activeTab = active;
      }, 100);
    },
    getCreditLetter() {
      this.getCreditLetterById(this.creditLetterId).then(({ data }) => {
        this.creditLetter.details = data;

        this.creditLetter.details.raw.issuer_id =
          data.raw.issuer_id != 0 ? data.raw.issuer_id : undefined;

        this.tempCreditLetter = {
          ...data.raw,
          ...data.meta,
        };

        this.tempCreditLetter.value = this.tempCreditLetter.value.replace(
          ".",
          ","
        );
        this.tempCreditLetter.supplier_value =
          this.tempCreditLetter.supplier_value.replace(".", ",");
        this.tempCreditLetter.profit_value =
          this.tempCreditLetter.profit_value.replace(".", ",");
      });
    },
    onCreateTransaction(total, reloadCreditLetter) {
      if (reloadCreditLetter) this.getCreditLetter();
      this.totalTransactions = total;
    },
    onLoadNotes(meta) {
      this.totalNotes = meta.total;
    },
    onLoadLogs(meta) {
      this.totalLogs = meta.total;
    },
    onChangeTabs(tab) {
      this.activeTab = tab;
    },
  },
};
</script>

<style lang="sass" scoped>
.travel-tabs
  height: 642px
  .tab-icons
    float: left
    border-right: 1px solid #e0e0e0
    width: 75px
    height: 642px
    text-align: center
    .tab
      float: left
      height: 60px
      width: 100%
      line-height: 56px
      cursor: pointer
      transition: .6s
      opacity: 0.6
      border-bottom: 1px solid #e0e0e0
      .alert
        position: absolute
        z-index: 10
        top: 39px
        right: 3px
        font-size: 21px
        &:hover
          background: none !important
    & :hover, .active
      filter: none
      -webkit-filter: grayscale(0)
      -webkit-filter: grayscale(0%)
      opacity: 1
    & .active-cancel
      background: #ffefef !important
      filter: none
      -webkit-filter: grayscale(0)
      -webkit-filter: grayscale(0%)
      opacity: 1
  .tab-content
    position: relative
    background: #fafafa
    height: 642px
    margin-left: 86px
    .title
      display: block
      border-bottom: 1px solid #efefef
      font-size: 14px
      font-weight: 600
      color: 86px !important
      padding: 8px 0 9px
      color: #be4178
      text-transform: uppercase
      margin-bottom: 20px !important
    .tab-scroll
      overflow: auto
      padding: 10px 20px 10px 10px
      height: 642px
      background: #FFF
</style>
