<template>
  <aRow class="header" align="middle" :gutter="20">
    <aCol :span="12">
      <div class="box">
        <div class="title cprimary">
          CARTA DE CRÉDITO ID {{ creditLetter.details.id }}
        </div>
        <div class="value mt-5 f10">
          <template
            v-if="creditLetter.details.customer.person_type === 'Pessoa Física'"
          >
            {{ creditLetter.details.customer.first_name }}
            {{ creditLetter.details.customer.last_name }}
          </template>
          <template v-else>
            {{ creditLetter.details.customer.trading_name }}
          </template>
        </div>
      </div>
    </aCol>

    <aCol class="" :span="12">
      <aRow :gutter="20">
        <aCol :span="8">
          <div class="box">
            <div class="title">TOTAL</div>
            <div class="value" style="color: #2196f3">
              {{ creditLetter.details.currency | formatCurrency }}
              {{ creditLetter.details.value | formatPrice }}
            </div>
          </div>
        </aCol>
        <aCol :span="8">
          <div class="box">
            <div class="title">DISPONÍVEL</div>
            <div class="value" style="color: #00aa21">
              {{ creditLetter.details.currency | formatCurrency }}
              {{ creditLetter.details.available_value | formatPrice }}
            </div>
          </div>
        </aCol>
        <aCol :span="8">
          <div class="box">
            <div class="title">UTILIZADO</div>
            <div class="value" style="color: red">
              {{ creditLetter.details.currency | formatCurrency }}
              {{ creditLetter.details.spent_value | formatPrice }}
            </div>
          </div>
        </aCol>
      </aRow>
    </aCol>
  </aRow>
</template>

<script>
import reusableThings from "@/mixins/general/reusableThings";
export default {
  name: "CreditLetterModalHeader",
  props: {
    creditLetter: Object,
  },
  mixins: [reusableThings],
};
</script>

<style lang="sass" scoped>
.header
  padding: 10px 0
  border-bottom: 1px solid #e0e0e0
  margin: 0 !important
  .box
    border-radius: 6px
    font-size: 14px
    padding: 10px
    border-radius: 6px
    border: 1px solid #ddd
    height: 58px
    .title
      color: #bcbec0
      font-weight: 600
    .value
      color: #333
      font-weight: 600
  h1
    font-weight: 600
    font-size: 18px !important
    margin: 0
    padding: 0
    letter-spacing: -1px
    text-transform: uppercase
</style>
