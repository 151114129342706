<template>
  <section>
    <a-divider class="mt-10 mb-20" />
    <h2 class="f14 mb-20 fw-600 cprimary">DADOS DO FORNECEDOR DE MILHAS</h2>

    <div class="card" v-for="({ id }, i) in repeaterRows" :key="i">
      <div
        @click="
          activeCollapse == id ? (activeCollapse = '') : (activeCollapse = id)
        "
      >
        <aRow class="head" :gutter="10">
          <aCol :span="23">
            <span class="ico cprimary">
              <a-icon type="user" />
              <span class="num">{{ id }}</span>
            </span>
            FORNECEDOR
          </aCol>
          <aCol v-if="!creditLetterFieldsDisabled" :span="1">
            <a-button
              v-if="repeaterRows.length == id"
              class="relative"
              type="danger"
              shape="circle"
              icon="delete"
              size="small"
              @click="removeRow(id)"
            />
          </aCol>
        </aRow>
      </div>

      <CreditLetterMilesSupplierCollapse
        v-show="activeCollapse == id"
        :form="form"
        :action="action"
        :id="id"
        :activeCollapse="activeCollapse"
        :creditLetter="creditLetter"
        :creditLetterFieldsDisabled="creditLetterFieldsDisabled"
      />
    </div>

    <div class="a-center mt-20">
      <a-button
        v-if="!creditLetterFieldsDisabled"
        @click="addRow(repeaterRows.length)"
        class="f12"
      >
        Adicionar fornecedor
      </a-button>

      <aFormItem class="none">
        <aInput
          v-decorator="[
            'credit_letter_miles_rows',
            { initialValue: repeaterRowsStr },
          ]"
        />
      </aFormItem>
    </div>
  </section>
</template>

<script>
// components
import CreditLetterMilesSupplierCollapse from "../collapses/CreditLetterMilesSupplierCollapse.vue";

export default {
  name: "CreditLetterMilesSection",
  props: {
    form: Object,
    action: String,
    creditLetter: Object,
    creditLetterFieldsDisabled: Boolean,
  },
  components: {
    CreditLetterMilesSupplierCollapse,
  },
  mixins: [],
  data() {
    return {
      repeaterRows: [],
      repeaterRowsStr: "",
      activeCollapse: 0,
    };
  },
  mounted() {
    if (this.creditLetter.details.meta.credit_letter_miles_rows !== undefined) {
      this.repeaterRows = JSON.parse(
        this.creditLetter.details.meta.credit_letter_miles_rows
      );

      this.repeaterRowsStr =
        this.creditLetter.details.meta.credit_letter_miles_rows;
    }
  },
  methods: {
    addRow(id) {
      let newId = id + 1;
      this.repeaterRows.push({
        id: newId,
      });

      this.repeaterRowsStr = JSON.stringify(this.repeaterRows);
      this.form.setFieldsValue({
        ["credit_letter_miles_rows"]: JSON.stringify(this.repeaterRows),
      });
    },
    removeRow(id) {
      for (let i in this.repeaterRows) {
        if (this.repeaterRows[i].id == id) {
          this.repeaterRows.splice(i, 1);
          break;
        }
      }

      this.repeaterRowsStr = JSON.stringify(this.repeaterRows);
      this.form.setFieldsValue({
        ["credit_letter_miles_rows"]: JSON.stringify(this.repeaterRows),
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.card
  cursor: pointer
  clear: both
  background: #fff
  border: 1px solid #ccc
  margin-bottom: 10px
  border-radius: 6px
  overflow: hidden
  .head
    cursor: pointer
    clear: both
    padding: 10px 20px
    background: #fff
    border-radius: 6px
    color: #42413f
    font-weight: 500
    .ico
      position: relative
      margin-right: 20px
      .num
        position: absolute
        right: -4px
        top: 0
        font-size: 11px
        color: #333
</style>
