<template>
  <section>
    <a-divider class="mt-10 mb-20" />

    <aFormItem class="none">
      <aInput v-decorator="['form_worker']" />
    </aFormItem>

    <h2 class="f14 fw-600 cprimary mb-10">DADOS DA MULTA DE CANCELAMENTO</h2>

    <aRow class="data-row mb-30">
      <aCol :span="6">
        <span class="label">ID Contrato (Gerado):</span>
        <template v-if="creditLetter.details.meta.generated_contract_id">
          <a
            @click="
              $router.push(
                `/contracts/list?open-id=${creditLetter.details.meta.generated_contract_id}&open-modal=true`
              )
            "
            >{{ creditLetter.details.meta.generated_contract_id }}</a
          >
        </template>
        <template v-else> --- </template>
      </aCol>

      <aCol :span="6">
        <span class="label">ID Contrato (Cancelado):</span>
        <template v-if="creditLetter.details.meta.canceled_contract_id">
          <a
            @click="
              $router.push(
                `/contracts/list?open-id=${creditLetter.details.meta.canceled_contract_id}&open-modal=true`
              )
            "
            >{{ creditLetter.details.meta.canceled_contract_id }}</a
          >
        </template>
        <template v-else> --- </template>
      </aCol>

      <aCol :span="6">
        <span class="label">ID Venda (Nova):</span>
        <template v-if="creditLetter.details.meta.generated_sale_id">
          <a
            @click="
              $router.push(
                `/sales/list?id=${creditLetter.details.meta.generated_sale_id}&open-modal=true`
              )
            "
          >
            {{ creditLetter.details.meta.generated_sale_id }}
          </a>
        </template>
        <template v-else> --- </template>
      </aCol>

      <aCol :span="6">
        <span class="label">ID Venda (Cancelada):</span>
        <template v-if="creditLetter.details.meta.canceled_sale_id">
          <a
            @click="
              $router.push(
                `/sales/list?id=${creditLetter.details.meta.canceled_sale_id}&open-modal=true`
              )
            "
          >
            {{ creditLetter.details.meta.canceled_sale_id }}
          </a>
        </template>
        <template v-else> --- </template>
      </aCol>
    </aRow>

    <aRow :gutter="20">
      <aCol :span="4">
        <HayaPriceField
          label="Multa do Cliente"
          placeholder="Insira"
          fieldKey="fine_fee_value"
          :fieldClass="'readonly'"
          :required="false"
          :form="form"
          :preIcon="true"
        />
      </aCol>

      <aCol :span="4">
        <HayaPriceField
          label="Multa do Fornecedor"
          placeholder="Insira"
          fieldKey="supplier_fine_fee_value"
          :fieldClass="'readonly'"
          :required="false"
          :form="form"
          :preIcon="true"
        />
      </aCol>

      <aCol :span="4">
        <HayaPriceField
          label="Valor Net"
          placeholder="Insira"
          fieldKey="fine_fee_net_value"
          :required="false"
          :form="form"
          :preIcon="true"
        />
      </aCol>

      <aCol :span="4">
        <HayaPriceField
          label="Taxas"
          placeholder="Insira"
          fieldKey="fine_fee_tax_value"
          :required="false"
          :form="form"
          :preIcon="true"
        />
      </aCol>
    </aRow>

    <template v-if="tempCreditLetter.status === 'Pagamento Ao Cliente'">
      <h2 class="f14 fw-600 cprimary mb-30">DADOS PARA PAGAMENTO AO CLIENTE</h2>

      <aRow>
        <aCol :span="24">
          <HayaRadioField
            label="Status do Pagamento do cliente"
            fieldKey="fine_fee_payment_status"
            :form="form"
            :required="true"
            :disabled="creditLetterFieldsDisabled"
            :options="[
              {
                label: 'Aprovado',
                value: 'Aprovado',
              },
              {
                label: 'Cancelado',
                value: 'Cancelado',
              },
            ]"
          />
        </aCol>
      </aRow>

      <template
        v-if="form.getFieldValue(`fine_fee_payment_status`) === 'Aprovado'"
      >
        <div class="card" v-for="({ id }, i) in repeaterRows" :key="i">
          <div
            @click="
              activeCollapse == id
                ? (activeCollapse = '')
                : (activeCollapse = id)
            "
          >
            <aRow class="head" :gutter="10">
              <aCol :span="23">
                <span class="ico cprimary">
                  <a-icon type="dollar" />
                  <span class="num">{{ id }}</span>
                </span>
                PAGAMENTO
              </aCol>
              <aCol v-if="!creditLetterFieldsDisabled" :span="1">
                <aButton
                  v-if="repeaterRows.length == id"
                  class="relative"
                  type="danger"
                  shape="circle"
                  icon="delete"
                  size="small"
                  @click="removeRow(id)"
                />
              </aCol>
            </aRow>
          </div>

          <template v-if="activeCollapse == id">
            <CreditLetterFineFeePaymentCollapse
              :form="form"
              :action="action"
              :id="id"
              :tempCreditLetter="tempCreditLetter"
              :creditLetter="creditLetter"
              :creditLetterFieldsDisabled="creditLetterFieldsDisabled"
            />
          </template>
        </div>

        <div class="a-center mt-20 mb-20">
          <aButton
            v-if="!creditLetterFieldsDisabled"
            @click="addRow(repeaterRows.length)"
            class="f12"
          >
            Adicionar pagamento
          </aButton>

          <aFormItem class="none">
            <aInput
              v-decorator="[
                'fine_fee_payment_rows',
                { initialValue: repeaterRowsStr },
              ]"
            />
          </aFormItem>
        </div>
      </template>
    </template>
  </section>
</template>

<script>
import HayaRadioField from "@/components/general/fields/HayaRadioField.vue";
import HayaPriceField from "@/components/general/fields/HayaPriceField.vue";
import CreditLetterFineFeePaymentCollapse from "../collapses/CreditLetterFineFeePaymentCollapse.vue";

export default {
  name: "CreditLetterContractSection",
  props: {
    form: Object,
    action: String,
    creditLetter: Object,
    creditLetterFieldsDisabled: Boolean,
    tempCreditLetter: Object,
  },
  components: {
    HayaRadioField,
    HayaPriceField,
    CreditLetterFineFeePaymentCollapse,
  },
  data() {
    return {
      paymentMethods: [],
      companyBanksList: [],
      repeaterRows: [],
      repeaterRowsStr: "",
      activeCollapse: 0,
    };
  },
  created() {
    this.$on("onChangeStatus", this.setContractsFineFeeFields);
  },
  mounted() {
    this.repeaterRows = this.tempCreditLetter.fine_fee_payment_rows
      ? JSON.parse(this.tempCreditLetter.fine_fee_payment_rows)
      : [];

    this.repeaterRowsStr = this.tempCreditLetter.fine_fee_payment_rows;
    this.form.setFieldsValue({
      fine_fee_payment_status: this.tempCreditLetter.fine_fee_payment_status,
      generated_contract_id:
        this.tempCreditLetter.generated_contract_id ?? undefined,
      generated_sale_id: this.tempCreditLetter.generated_sale_id ?? undefined,
      canceled_contract_id:
        this.tempCreditLetter.canceled_contract_id ?? undefined,
      canceled_sale_id: this.tempCreditLetter.canceled_sale_id ?? undefined,
      fine_fee_value: this.tempCreditLetter.fine_fee_value ?? undefined,
      supplier_fine_fee_value:
        this.tempCreditLetter.supplier_fine_fee_value ?? undefined,
      fine_fee_net_value: this.tempCreditLetter.fine_fee_net_value ?? undefined,
      fine_fee_tax_value: this.tempCreditLetter.fine_fee_tax_value ?? undefined,
    });

    this.$http
      .get(`/company-bank-accounts/list?page=1&per_page=100&status=Ativo`)
      .then(({ data }) => {
        this.companyBanksList = data.data.map((companyBank) => {
          return {
            label: `${companyBank.id} - ${companyBank.company_name} (${companyBank.account})`,
            value: companyBank.id,
          };
        });
      });
  },
  methods: {
    addRow(id) {
      let newId = id + 1;
      this.repeaterRows.push({
        id: newId,
      });

      this.repeaterRowsStr = JSON.stringify(this.repeaterRows);
      this.form.setFieldsValue({
        ["fine_fee_payment_rows"]: JSON.stringify(this.repeaterRows),
      });
    },
    removeRow(id) {
      for (let i in this.repeaterRows) {
        if (this.repeaterRows[i].id == id) {
          this.repeaterRows.splice(i, 1);
          break;
        }
      }

      this.repeaterRowsStr = JSON.stringify(this.repeaterRows);
      this.form.setFieldsValue({
        ["fine_fee_payment_rows"]: JSON.stringify(this.repeaterRows),
      });
    },
    setContractsFineFeeFields(value) {
      if (value === "Pagamento Ao Cliente") {
        setTimeout(() => {
          this.form.setFieldsValue({
            fine_fee_payment_status:
              this.tempCreditLetter.fine_fee_payment_status,
          });
        }, 100);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.card
  cursor: pointer
  clear: both
  background: #fff
  border: 1px solid #ccc
  margin-bottom: 10px
  border-radius: 6px
  overflow: hidden
  .head
    cursor: pointer
    clear: both
    padding: 10px 20px
    background: #fff
    border-radius: 6px
    color: #42413f
    font-weight: 500
    .ico
      position: relative
      margin-right: 20px
      .num
        position: absolute
        right: -4px
        top: 0
        font-size: 11px
        color: #333
</style>

<style lang="scss" scoped>
.data-row {
  background: #fff;
  padding: 10px;
  border: 1px solid #ddd !important;
  margin: 0 0 10px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  .label {
    display: block;
    font-size: 12px;
    font-weight: 500;
    color: #aaa;
  }
}
</style>
